// MUI
import { Grid } from '@mui/material';

// Styling
import { styled } from '@mui/system';

export const UniversalBox = styled(Grid)({
  backgroundColor: '#E5E5E5',
  borderRadius: '5em',
  padding: '3em',
  marginTop: '2em',
  marginBottom: '2em',
  overflowWrap: 'break-word',
});
