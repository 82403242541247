// General
import React from 'react';

// MUI
import { Grid, Typography } from '@mui/material';

// Components
import { UniversalBox } from './components/containers/UniversalBox';

// Icons
import { ReactComponent as InstagramIcon } from './assets/images/instagram.svg';
import { ReactComponent as TelegramIcon } from './assets/images/telegram.svg';
import { ReactComponent as WhatsappIcon } from './assets/images/whatsapp.svg';

// Styling
import { GlobalStyles } from '@mui/material';
import { globalStylesConfig } from './globalStyles';
import {
  ProfilePhoto,
  StyledText,
  StyledIconButton,
  StyledSvg,
  StyledSvgWA,
} from './styles';

function App() {
  return (
    <div>
      <GlobalStyles styles={globalStylesConfig} />
      <Grid container direction={'row'} justifyContent={'center'}>
        <UniversalBox
          container
          direction={'row'}
          justifyContent={'space-around'}
          item
          xl={10}
          lg={10}
          md={10}
          sm={11}
          xs={11}
        >
          {/*PORTRAIT*/}
          <ProfilePhoto item xl={3} lg={5} md={5} sm={12} xs={12} />
          {/*PROFILE*/}
          <Grid item xl={8} lg={6} md={12} sm={12} xs={12}>
            <Grid container direction={'column'} justifyContent={'flex-start'}>
              {/*HEADER*/}
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <Typography variant={'h4'}>Привет!</Typography>
              </Grid>
              {/*INFO*/}
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <StyledText variant={'h6'}>
                  Я - мастер маникюра с опытом работы более 6 лет. Меня зовут
                  Аня, и я с удовольствием сделаю вам красивый и качественный
                  маникюр!
                </StyledText>
                <StyledText variant={'h6'}>
                  У меня есть множество дизайнов, чтобы удовлетворить любые ваши
                  потребности и предпочтения. Я люблю экспериментировать с
                  цветами, текстурами и узорами, поэтому у вас будет множество
                  вариантов, чтобы найти тот, который подходит именно вам.
                </StyledText>
                <StyledText variant={'h6'}>
                  Я убеждена, что маникюр - это не только украшение ваших
                  ногтей, но и отражение вашей личности. Поэтому я уделяю
                  большое внимание деталям и работаю только с теми клиентами,
                  которые ищут нечто особенное и уникальное.
                </StyledText>
              </Grid>
            </Grid>
          </Grid>
        </UniversalBox>
        {/*SOCIAL*/}
        <UniversalBox
          container
          direction={'row'}
          justifyContent={'space-around'}
          item
          xl={3}
          lg={5}
          md={7}
          sm={11}
          xs={11}
        >
          {/*INSTAGRAM*/}
          <StyledIconButton
            href={'https://instagram.com/yf_nails.ekb'}
            target={'_blank'}
          >
            <StyledSvg>
              <InstagramIcon />
            </StyledSvg>
          </StyledIconButton>
          {/*TELEGRAM*/}
          <StyledIconButton href={'https://t.me/yfnailsekb'} target={'_blank'}>
            <StyledSvg>
              <TelegramIcon />
            </StyledSvg>
          </StyledIconButton>
          {/*WHATSAPP*/}
          <StyledIconButton
            href={
              'https://api.whatsapp.com/qr/GYFRYK3ILNYYP1?autoload=1&app_absent=0'
            }
            target={'_blank'}
          >
            <StyledSvgWA>
              <WhatsappIcon />
            </StyledSvgWA>
          </StyledIconButton>
        </UniversalBox>
      </Grid>
    </div>
  );
}

export default App;
