// MUI
import { Grid, Typography, IconButton } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';

// Assets
import ProfileImage from './assets/images/profile.png';

// Styling
import { styled } from '@mui/system';

export const ProfilePhoto = styled(Grid)(({ theme }) => ({
  height: '50vh',
  backgroundSize: 'cover',
  backgroundPosition: 'left',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${ProfileImage})`,
  borderRadius: '5em',
  [theme.breakpoints.down('lg')]: {
    marginBottom: '2em',
  },
}));

export const StyledText = styled(Typography)({
  marginTop: '1em',
});

export const StyledIconButton = styled(IconButton)({
  '&:hover': {
    backgroundColor: 'transparent',
  },
});

export const StyledSvg = styled(SvgIcon)({
  width: '4em',
  height: '3em',
});

export const StyledSvgWA = styled(SvgIcon)({
  width: '4em',
  height: '4em',
});
